<template>
	<b-modal v-model="show" id="print-dispatch-summary" :title="title" size="xl" ref="print-dispatch-summary"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-container v-show="selDispatch !== null">
			<div class="page-container" v-for="(copy, key) in copies" :id="getKey(key)" :key="key">
				<!-- Header -->
				<DispatchSummaryHeader :selDispatch="selDispatch" />

				<!-- Source and Destination -->
				<b-row class="px-2 mb-2">
					<b-col sm="6" class="border border-light border-1 p-2">
						<div class="section-header">Source</div>
						<!-- Company -->
						<div class="form-field">Company: <span class="form-value-uppercase">{{
							sourceCompanyName
						}}</span>
						</div>
						<!-- Warehouse -->
						<div class="form-field">Warehouse: <span class="form-value-uppercase">{{
							sourceLocationName
						}}</span>
						</div>
						<!-- Address -->
						<div class="form-field">Address: <span class="form-value-uppercase">{{
							sourceLocation && sourceLocation.address ? sourceLocation.address : '-'
						}}</span>
						</div>
						<!-- Date Deployed -->
						<div class="form-field">Date Dispatched: <span class="form-value-uppercase">{{
							selDispatch.dateDeployed ? showFormattedDate(selDispatch.dateDeployed) : '-'
						}}</span>
						</div>
					</b-col>

					<b-col sm="6" class="border border-light border-1 p-2">
						<div class="section-header">Destination</div>
						<!-- Company -->
						<div class="form-field">Company: <span class="form-value-uppercase">{{
							destinationCompanyName
						}}</span>
						</div>
						<!-- Warehouse -->
						<div class="form-field">Warehouse: <span class="form-value-uppercase">{{
							destinationLocationName
						}}</span>
						</div>
						<!-- Address -->
						<div class="form-field">Address: <span class="form-value-uppercase">{{
							destinationLocation && destinationLocation.address ? destinationLocation.address : "-"
						}}</span>
						</div>
						<!-- Date Received -->
						<div class="form-field">Date Received: <span class="form-value-uppercase">{{
							selDispatch.dateReceived ? showFormattedDate(selDispatch.dateReceived) : '-'
						}}</span>
						</div>
					</b-col>
				</b-row>

				<!-- Assets -->
				<DispatchSummaryAssetTypes :selDispatch="selDispatch" />

				<!-- Remarks -->
				<b-row class="px-2 mt-2 mb-4 remarks-section">
					<b-col sm="6" class="border border-light border-1 p-2">
						<span class="section-header">DISPATCH REMARKS:</span>&nbsp;
						<span class="remarks-value" v-html="dispatchRemarks"></span>
					</b-col>
					<b-col sm="6" class="border border-light border-1 p-2">
						<span class="section-header">RECEIPT REMARKS:</span>&nbsp;
						<span class="remarks-value" v-html="receiptRemarks"></span>
					</b-col>
				</b-row>

				<!-- Signatories -->
				<DispatchSummarySignatories :selDispatch="selDispatch" :deployedByUser="deployedByUser"
					:receivedByUser="receivedByUser" />

				<!-- Footer -->
				<DispatchSummaryFooter :copyOwner="copy" />
			</div>
		</b-container>

		<template #modal-footer>
			<div class="w-100">
				<span class="float-right mr-3">
					<b-button variant="secondary" @click="show = false" class="footer-button mr-2"
						:disabled="disableConfirmButtons">
						Close
					</b-button>
					<b-button v-show="hasDispatchNo" variant="primary" @click="confirmPrintDispatch" class="footer-button"
						:disabled="disableConfirmButtons">
						Print
					</b-button>
				</span>
			</div>
		</template>

		<ConfirmPrintDispatch :selDispatch="selDispatch" @onPrintDispatch="printDispatch" />
	</b-modal>
</template>

<script>
// Components
import DispatchSummaryHeader from './summary/DispatchSummaryHeader';
import DispatchSummaryAssetTypes from './summary/DispatchSummaryAssetTypes';
import DispatchSummarySignatories from './summary/DispatchSummarySignatories';
import DispatchSummaryFooter from './summary/DispatchSummaryFooter';
import ConfirmPrintDispatch from './summary/ConfirmPrintDispatch';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import _ from 'lodash';

export default {
	name: 'print-dispatch-summary',
	components: {
		DispatchSummaryHeader,
		DispatchSummaryAssetTypes,
		DispatchSummarySignatories,
		DispatchSummaryFooter,
		ConfirmPrintDispatch,
		Loading,
	},
	props: {
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			show: false,
			selDispatch: {},
			sourceLocation: {},
			destinationLocation: {},
			deployedByUser: '',
			receivedByUser: '',

			copies: [''],
			configTimeout: 5000,
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'View Dispatch';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		dispatchRemarks() {
			let remarks = '-';
			let proofOfDispatch = this.selDispatch ? this.selDispatch.proofOfDispatch : null;

			if (proofOfDispatch) {
				remarks = proofOfDispatch.remarks && proofOfDispatch.remarks.length > 0 ? proofOfDispatch.remarks : '-';
			} else {
				let notes = this.selDispatch ? this.selDispatch.notes : null;
				remarks = notes && notes.length > 0 ? notes : '-';
			}

			return this.truncateRemarks(remarks);
		},
		receiptRemarks() {
			let remarks = '-';

			let proofOfReceipt = this.selDispatch.proofOfReceipt;
			if (proofOfReceipt) {
				remarks = proofOfReceipt.remarks && proofOfReceipt.remarks.length > 0 ? proofOfReceipt.remarks : '-';
			}

			return this.truncateRemarks(remarks);
		},
		dispatchNo() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			return dispatch.dispatchNo ? dispatch.dispatchNo : "-";
		},
		hasDispatchNo() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			return dispatch.dispatchNo && dispatch.dispatchNo.length > 0 ? true : false;
		},
		sourceCompanyName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let source = dispatch.source ? dispatch.source : {};
			return source && source.company && source.company.length > 0 ? source.company : '-'
		},
		sourceLocationName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let source = dispatch.source ? dispatch.source : {};
			return source && source.storageLocation && source.storageLocation.length > 0 ? source.storageLocation : '-'
		},
		destinationCompanyName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let destination = dispatch.destination ? dispatch.destination : {};
			return destination && destination.company && destination.company.length > 0 ? destination.company : '-'
		},
		destinationLocationName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let destination = dispatch.destination ? dispatch.destination : {};
			return destination && destination.storageLocation && destination.storageLocation.length > 0 ? destination.storageLocation : '-'
		},
		isDriverActive() {
			let driver = this.selDispatch && this.selDispatch.driver ? this.selDispatch.driver : {};
			return driver && driver.userId && driver.userId.length > 0 ? true : false;
		}
	},
	mounted() {
		EventBus.$on('onPrintDispatch', (selDispatch) => {
			this.onReset();

			if (!_.isEmpty(selDispatch)) {
				this.selDispatch = DispatchUtil.cleanupFields(selDispatch);
				this.retrieveOtherDetails(selDispatch);
			}

			this.$bvModal.show('print-dispatch-summary');
		});
	},
	methods: {
		retrieveOtherDetails(selDispatch) {
			// Show loader
			this.isLoading = true;

			let sourceLocId = selDispatch.source.storageLocationId;
			this.sourceLocation = this.allStorageLocationsObj[sourceLocId];

			let destinationLocId = selDispatch.destination.storageLocationId;
			this.destinationLocation = this.allStorageLocationsObj[destinationLocId];

			if (this.isDriverActive || this.selDispatch.status === 'Draft') {
				this.deployedByUser = this.getNameDisplay(this.allUsersObj[selDispatch.createdBy]);
			} else {
				this.deployedByUser = this.getNameDisplay(this.allUsersObj[selDispatch.deployedBy]);
			}

			if (selDispatch.receivedBy && selDispatch.receivedBy.length > 0) {
				this.receivedByUser = this.getNameDisplay(this.allUsersObj[selDispatch.receivedBy]);
			}

			// Check for loader
			this.isLoading = false;
		},

		confirmPrintDispatch(event) {
			event.preventDefault();
			this.$bvModal.show('confirm-print-dispatch');
		},
		async printDispatch(param) {
			try {
				// show loading indicator
				this.isLoading = true;

				let type = param.type;
				if (type === 'Single Copy' && param.copies.length === 1) {
					this.copies = param.copies;
					await this.downloadSingleCopy(this.copies); this.$bvModal.hide('print-dispatch-summary');
					this.onReset();
				} else {
					this.copies = [...param.copies];
					setTimeout(async () => {
						await this.downloadMultipleCopies(this.copies);
						this.$bvModal.hide('print-dispatch-summary');
						this.onReset();
					}, this.configTimeout);
				}

			} catch (error) {
				console.error('Error occurred:', error);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		// Multiple Copies
		async downloadMultipleCopies(copies) {
			let imageElements = [];
			for (let i = 0; i < copies.length; i++) {
				const containerElement = document.getElementById(this.getKey(i));
				imageElements.push(containerElement);
			}

			const zip = new JSZip();
			const promises = [];
			imageElements.forEach((element, index) => {
				let filename = this.getFileName(copies[index]);
				promises.push(this.captureAndAddToZip(element, zip, filename));
			});
			await Promise.all(promises);

			const content = await zip.generateAsync({ type: 'blob' });

			let zipFilename = this.dispatchNo + '.zip';
			saveAs(content, zipFilename);
		},
		async captureAndAddToZip(element, zip, filename) {
			// Add a delay before capturing the image
			await new Promise(resolve => setTimeout(resolve, this.configTimeout));

			const canvas = await html2canvas(element);
			return new Promise((resolve) => {
				canvas.toBlob((blob) => {
					zip.file(filename, blob);
					resolve();
				});
			});
		},

		// Single Copy
		async downloadSingleCopy(copies) {
			// Show loading indicator before starting the download
			this.isLoading = true;

			try {
				const promises = copies.map(async (copy, index) => {
					const containerElement = document.getElementById(this.getKey(index));
					const filename = this.getFileName(copy);
					await this.captureAndSaveImage(containerElement, filename);
				});

				await Promise.all(promises);
			} catch (error) {
				console.error('Error occurred during download:', error);
			} finally {
				// Hide modal and reset
				this.isLoading = false;
				this.$bvModal.hide('print-dispatch-summary');
				this.onReset();
			}
		},
		async captureAndSaveImage(element, filename) {
			await new Promise(resolve => setTimeout(resolve, this.configTimeout));

			const canvas = await html2canvas(element);
			canvas.toBlob((blob) => {
				saveAs(blob, filename);
			});
		},

		getFileName(copyOwner) {
			return this.dispatchNo + '_' + copyOwner + '_Copy.png';
		},
		getKey(key) {
			return 'dispatch-summary-' + key;
		},
		onReset() {
			this.selDispatch = {};
			this.sourceLocation = {};
			this.destinationLocation = {};
			this.deployedByUser = '';
			this.receivedByUser = '';
			this.copies = [''];

			// Check for loader
			this.isLoading = false;
			this.show = true;
		},

		// UTILS
		getNameDisplay(userObj) {
			return userObj ? userObj.firstName + ' ' + userObj.lastName : 'TAWItech Support';
		},
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		truncateRemarks(string) {
			if (string.length > 200) {
				let remarks = string.substring(0, 200) + '...';
				return this.breakRemarks(remarks, 50);
			}
			return this.breakRemarks(string, 50);
		},
		breakRemarks(remarks, length) {
			return remarks.length > length ? remarks.replace(new RegExp(`([^\\s]{${length}})`, 'g'), '$1<br>') : remarks;
		}
	},
	beforeDestroy() {
		EventBus.$off('onPrintDispatch');
	},
};
</script>

<style scoped>
.page-container {
	padding-top: 1em;
	padding-left: 1.75em;
	padding-right: 1.75em;
	position: relative;
    max-width: 100wh;
	aspect-ratio: 13 / 9;  
	overflow: visible;
}

.section-header {
	text-transform: uppercase;
	color: #024F98;
	font-size: small;
	font-style: normal;
	font-weight: bold;
	line-height: normal;
}

.form-field {
	font-size: small;
	text-align: left;
	text-transform: uppercase;
	color: #4A4A4A;
	font-weight: 400;
	line-height: normal;
}

.form-value {
	font-size: small;
	font-weight: bold;
	text-align: left;
	line-height: medium;
	color: #484554;
}

.form-value-uppercase {
	font-size: small;
	text-align: left;
	text-transform: uppercase;
	color: #484554;
	font-weight: 700;
	line-height: normal;
}

.remarks-section {
	height: 7em;
}

.remarks-value {
	font-size: small;
	font-style: italic;
	background-color: #FBDDB3
}
</style>
